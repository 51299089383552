import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic_animal_1 from '../assets/images/pic_animal_1.jpg'
import pic_animal_2 from "../assets/images/pic_animal_2.jpg";
import pic_animal_3 from "../assets/images/pic_animal_3.jpg";
import pic_animal_11 from "../assets/images/pic_animal_11.jpg";
import pic_animal_10 from "../assets/images/pic_animal_10.jpg";
import pic_animal_8 from "../assets/images/pic_animal_8.jpg";
import Biographie from "../components/Biographie";


const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Vincent DUCOS photographe animaux</title>
            <meta name="description" content="Photos animaux Poitiers" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Photos animalières</h2>
                    </header>
                    <p>
                        Je suis spécialisé dans la photographie des petites et grosses bêtes.
                        <br/>Votre animal de compagnie fait partie intégrante de votre famille. Qu'il soit à poils, plumes ou écailles, il partage votre vie avec enthousiasme et vos tracas comme vos joies.
                        <br/>Immortalisez votre animal, seul ou accompagné de toute la famille ! Je réalise les photos en intérieur ou extérieur, selon vos envies.
                    </p>
                    <div className="box">
                        <header className="major">
                            <h3>Réservation</h3>
                        </header>
                        <p>
                            Je propose différentes formules suivant votre demande. Je me déplace à votre domicile pour photographier vos compagnons de vie.
                            <br/>Pour tout renseigements concernant les tarifs merci de me contacter via le formulaire situé en  bas de page.
                        </p>
                    </div>
                </div>
            </section>
            <section id="one">
                <div className="inner">
                    <div className="box alt">
                        <div className="grid-wrapper">
                            <div className="col-4"><span className="image fit"><img src={pic_animal_1} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_animal_8} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_animal_3} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_animal_2} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_animal_10} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_animal_11} alt="" /></span></div>
                        </div>
                    </div>
                </div>
            </section>
            <Biographie/>
        </div>

    </Layout>
)

export default Landing